@btn-border-radius-base: 3px;
@btn-font-weight: 500;
@btn-text-shadow: none;
@btn-shadow: none;
@btn-default-bg: @border-color-base;
@btn-text-hover-bg: @border-color-base;
@btn-font-size-lg: @font-size-base;
@btn-height-base: 36px;

.@{btn-prefix-cls}:not(.@{btn-prefix-cls}-primary):not(.@{btn-prefix-cls}-dangerous) {
  &:hover,
  &:focus,
  &:active {
    color: @btn-default-color;
    border-color: darken(@btn-default-bg, 2%);
    background-color: darken(@btn-default-bg, 2%);
  }
}

.@{btn-prefix-cls}-dangerous.@{btn-prefix-cls}-text {
  &:hover,
  &:focus,
  &:active {
    background-color: rgba(@btn-danger-bg, 10%);
  }
}
