@table-bg: @white;
@table-header-bg: @white;
@table-header-color: @grey;


.@{table-prefix-cls} {
  table {
    border: solid 1px @border-color-base;
  }

  tr:last-child td {
    border-bottom: none;
  }
}
