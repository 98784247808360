@form-item-trailing-colon: false;
@input-height-base: 36px;

.@{input-prefix-cls} {
  &-prefix {
    margin-right: 8px;
  }
}

.@{form-item-prefix-cls} {
  &-with-help {
    margin-bottom: 12px;
  }

  &-explain {
    margin-top: 6px;
  }

  &-has-error {
    .@{form-item-prefix-cls}-label > label {
      color: @error-color;
    }
  }

  & nz-date-picker {
    display: block;
  }

  &-label {
    text-align: left;
    font-weight: 500;

    > label {
      color: @grey;
      flex-direction: row-reverse;

      &::before {
        margin-left: 4px;
        margin-right: 0;
      }

      &::after {
        margin: 0;
      }
    }
  }
}
