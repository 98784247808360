@page-header-padding: 0;

.@{pageheader-prefix-cls} {
  border-bottom: solid 1px @border-color-base;
  margin-bottom: 24px;

  &-heading {
    align-items: center;
  }

  &-heading-title {
    font-weight: 500;
    line-height: 1;
  }

  .@{breadcrumb-prefix-cls} {
    font-weight: 400;
  }
}
