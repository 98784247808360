.logo {
  padding: 24px 24px 12px;
  display: flex;
  align-items: center;
}

.logo__image {
  height: 24px;
}

.logo__text {
  margin-left: 12px;
  display: flex;
  flex-direction: column;

  h5 {
    color: @white;
    margin: 0;
    font-size: @font-size-base + 2px;
    font-weight: @typography-title-font-weight;
    line-height: 1;
  }

  small {
    line-height: 1;
    color: @white;
    opacity: 0.5;
  }
}

.@{layout-prefix-cls}-sider-collapsed {
  .logo {
    justify-content: center;
  }

  .logo__text {
    display: none;
  }
}
