@alert-success-border-color: @success-color;
@alert-info-border-color: @info-color;
@alert-warning-border-color: @warning-color;
@alert-error-border-color: @error-color;

.@{alert-prefix-cls} {
  border-radius: 0;
  border-width: 0;
  border-left-width: 2px;
  align-items: center;
  margin-bottom: 12px;

  &-message {
    font-weight: 700;
  }

  &-with-description &-message {
    margin-bottom: 0;
  }

  &-success,
  &-success &-message {
    color: @success-color;
  }

  &-warning,
  &-warning &-message {
    color: @warning-color;
  }

  &-error,
  &-error &-message {
    color: @error-color;
  }

  &-info,
  &-info &-message {
    color: @info-color;
  }
}
