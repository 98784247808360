@menu-item-active-bg: @primary-color;

.@{menu-prefix-cls} {
  padding: 12px 20px;
  transition: none;

  &-item {
    border-radius: 3px;
    transition: none;

    .anticon {
      position: relative;
      top: 1px;
      font-size: 16px;
      transition: none;

      + span {
        transition: none;
      }
    }
  }

  &-item-selected,
  &-item-selected:hover {
    color: @white;
    a,
    a:hover {
      color: @white;
    }
  }
}
