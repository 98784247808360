.layout-menu-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.@{layout-prefix-cls}-sider {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
  transition: none;

  + * {
    margin-left: 288px;
    margin-right: 48px;
  }

  &-collapsed {
    + * {
      margin-left: 128px;
    }

    .@{divider-prefix-cls}-horizontal {
      display: none;
    }
  }

  &-children {
    overflow: auto;
    display: flex;
    flex-direction: column;

    nav {
      flex: 1;
      display: flex;
      flex-direction: column;

      .@{divider-prefix-cls}-horizontal {
        flex: 1;
        border-top: none;
        border-bottom: solid 1px @white;
        margin: 12px 0;
        display: block;
      }
    }
  }

  .@{divider-prefix-cls}-horizontal {
    margin: 12px;
    width: auto;
    min-width: 0;
    border-top-color: @white;
    border-bottom-color: @white;
    opacity: 0.05;
  }
}

[nz-sider-footer] {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.@{layout-prefix-cls}-has-sider .@{layout-prefix-cls}-has-sider .@{layout-prefix-cls}-sider {
  left: 80px;
  z-index: 101;
  border-right: solid 1px @border-color-base;

  .@{menu-prefix-cls}-inline {
    border-right: 0;
  }

  + * {
    margin-left: 240px;
    margin-right: 0;
  }

  &-collapsed {
    + * {
      margin-left: 10px;
    }
  }
}

.@{layout-prefix-cls}-sider-trigger {
  background-color: @white;
  color: @grey;
  position: absolute;
  top: 60px;
  width: 24px !important;
  height: 24px;
  border-radius: 50%;
  right: -12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px @stroke;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
  font-size: 8px;
  transform: rotate(0deg);
  transition: transform .2s;
}

.@{layout-prefix-cls}-sider-collapsed .@{layout-prefix-cls}-sider-trigger {
  transform: rotate(180deg);
}
